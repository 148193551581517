import { Children, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import tabsContext from './tabsContext';

const TabBody: React.FC = ({ children }) => {
    const { tabIndex, setTabIndex, disabled, swipeableRef } = useContext(tabsContext);

    const history = useHistory();

    const location = useLocation();

    const handleIndexChange = (index: number) => {
        setTabIndex(index);

        const child: any = Children.toArray(children)[index];

        child?.props?.to && history.push(child.props.to);
    };

    useEffect(() => {
        Children.toArray(children).find(
            (child: any, index: number) =>
                child.props.to === location.pathname && setTabIndex(index)
        );
    }, [children, location.pathname, setTabIndex]);

    return (
        <SwipeableViews
            index={tabIndex}
            onChangeIndex={handleIndexChange}
            className='swipeable-root'
            enableMouseEvents
            disabled={disabled}
            animateHeight={true}
            ref={swipeableRef}
        >
            {children}
        </SwipeableViews>
    );
};

export default TabBody;
