import { AddressZero } from '@ethersproject/constants';
import { isString } from './validations';

export const formatAccount = (address: string) =>
    `${(address || AddressZero).slice(0, 6)}...${(address || AddressZero).slice(-4)}`;

export const formatUSD = (value: number | string, decimals = 2) => {
    if (!value && value !== 0) {
        console.error('formatUSD: Value is not defined');
        return 0;
    }

    if (isNaN(+value)) {
        console.error('formatUSD: Value is not a number');
        return 0;
    }

    return (+value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: decimals,
    });
};

export const formatPercentages = (value: number | string, decimals: number = 2) => {
    if (!value && value !== 0) {
        console.error('formatPercentages: Value is not defined');
        return (0).toFixed(decimals);
    }

    if (isNaN(+value)) {
        console.error('formatPercentages: Value is not a number');
        return (0).toFixed(decimals);
    }

    return (+value).toFixed(decimals);
};

export const formatAmountWithSuffix = (
    value: number | string,
    decimals: number = 2,
    minumumValue?: number
) => {
    if (!value && value !== 0) {
        console.error('formatAmountWithSuffix: Value is not defined');
        return 0;
    }

    if (isNaN(+value)) {
        console.error('formatAmountWithSuffix: Value is not a number');
        return 0;
    }

    const parsedValue = +value;

    if (minumumValue && parsedValue < minumumValue) {
        return parsedValue.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: decimals,
        });
    }

    switch (true) {
        case parsedValue >= 1000000000:
            return `${(parsedValue / 1000000000).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: decimals,
            })}B`;
        case parsedValue >= 1000000:
            return `${(parsedValue / 1000000).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: decimals,
            })}M`;
        case parsedValue >= 1000:
            return `${(parsedValue / 1000).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: decimals,
            })}K`;
        default:
            return parsedValue.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: decimals,
            });
    }
};

export const splitStringAndJoin = (value: string, position: number, join: string) => {
    const split = [...value];

    split.splice(position, 0, join);

    return split;
};

export const splitDecimals = (value: number | string) => {
    if (!value) {
        console.error('splitDecimals: Value is not defined');
        return 0;
    }

    if (!isString(value)) {
        console.error('splitDecimals: Value is not a string');
        return 0;
    }

    const str = value.toString().split('.');

    return (
        <>
            <span>{str[0]}.</span>
            <span className='decimals'>{str[1]}</span>
        </>
    );
};
