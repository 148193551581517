import { ReactNode } from 'react';
import { ErrorIcon, SocialIcons } from '../assets/icons';
import MaintenanceIcon from '../assets/maintenance.png';
import SpoolWordLogo from '../assets/logos/SpoolWordLogo';
import { config } from '../config/config';
import Portal from '../components/Portal';

interface Props {
    children: ReactNode;
    mode: boolean;
    development: boolean;
    developmentText?: string;
}

const Maintenance: React.FC<Props> = ({
    children,
    mode,
    development,
    developmentText = 'DEVELOPMENT',
}) => {
    return (
        <>
            {mode ? (
                <main>
                    <section id='maintenance'>
                        <div className='container'>
                            <img
                                src={MaintenanceIcon}
                                alt='Spool logo with screwdriver'
                            />

                            <h2>Spool's interface is levelling up.</h2>
                            <p>
                                Thank you for your patience as the Spool website undergoes
                                a scheduled UI upgrade, to offer you a smoother and
                                aesthetically satisfying experience.
                            </p>
                            <p>Estimated Update Time: 1 to 2 hours.</p>
                            <SpoolWordLogo />
                            <p>
                                <span>&copy;</span> {new Date().getFullYear()} Spool. All
                                rights reserved.
                            </p>
                            <div className='icons-container'>
                                {Object.entries(config.socialMedias).map(
                                    ([name, link]) => (
                                        <a
                                            key={name}
                                            href={link}
                                            target='_blank'
                                            rel='noreferrer noopener'
                                        >
                                            {SocialIcons[name]}
                                        </a>
                                    )
                                )}
                            </div>
                        </div>
                    </section>
                </main>
            ) : (
                children
            )}
            {development && (
                <Portal>
                    <div className='dev-env-warning'>
                        <ErrorIcon />
                        {developmentText}
                    </div>
                </Portal>
            )}
        </>
    );
};

export default Maintenance;
