import React, { ReactNode, useEffect, useState } from 'react';
import { Theme } from '../../config/constants';
import ThemeContext from './themeContext';

const ThemeState: React.FC<ReactNode> = ({ children }) => {
    const [theme, setTheme] = useState<Theme>(
        (localStorage.getItem('preferredTheme') as Theme) || ''
    );

    const toggleTheme = () => {
        setTheme(theme === Theme.DARK ? Theme.LIGHT : Theme.DARK);
    };

    // first time check for preferred scheme based on browser settings
    useEffect(() => {
        if (localStorage.getItem('preferredTheme')) return;

        localStorage.setItem('preferredTheme', Theme.DARK);
        setTheme(Theme.DARK);
    }, []);

    useEffect(() => {
        if (localStorage.getItem('preferredTheme') && theme) {
            localStorage.setItem('preferredTheme', theme);
        }
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeState;
