const Ledger = () => (
    <svg
        width='30'
        height='30'
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='ledger'
    >
        <path
            d='M24.8362 0H11.2243V18.2682H29.4926V4.75303C29.5 2.18684 27.4024 0 24.8362 0ZM7.044 0H4.76046C2.19428 0 0 2.09014 0 4.76046V7.044H7.044V0ZM0 11.3209H7.044V18.3649H0V11.3209ZM22.456 29.5892H24.7395C27.3057 29.5892 29.5 27.4991 29.5 24.8288V22.5527H22.456V29.5892ZM11.2243 22.5527H18.2683V29.5967H11.2243V22.5527ZM0 22.5527V24.8362C0 27.4024 2.09014 29.5967 4.76046 29.5967H7.044V22.5527H0Z'
            fill='white'
        />
    </svg>
);

export default Ledger;
