import React, { useContext, useEffect, useState } from 'react';
import themeContext from '../context/theme/themeContext';
import useIsMounted from '../hooks/useIsMounted';
import CollapsibleArea from './CollapsibleArea';
import tabsContext from './Tabs/tabsContext';

type Props = {
    updateHeightOnToggle?: boolean;
};

const RiskDisclaimer: React.FC<Props> = ({ updateHeightOnToggle = false }) => {
    const { theme } = useContext(themeContext);
    const { updateHeight } = useContext(tabsContext);

    const [collapsed, setCollapsed] = useState(false);

    const isMounted = useIsMounted();

    const toggle = () => setCollapsed((prev) => !prev);

    useEffect(() => {
        if (!updateHeightOnToggle) return;

        updateHeight();
    }, [updateHeightOnToggle, collapsed, isMounted, updateHeight]);

    return (
        <div className={`risk-disclaimer ${theme}`}>
            <p>
                <span className='title'>Risk disclaimer: </span>
                While Spool has been audited multiple times, there could be unforeseen
                vulnerabilities. Please use at your own risk.
                <span className='trigger' onClick={toggle}>
                    {collapsed ? ' Less' : ' Read More'}
                </span>
            </p>
            <CollapsibleArea className='risk-disclaimer-content' collapsed={collapsed}>
                <p>
                    Although Spool has been carefully audited by multiple industry
                    leaders, DeFi is still in its infancy and Spool is an Alpha product.
                    Users should only risk what they can afford to lose. Any user that
                    interacts with the contract and/or connects their wallet to Spool.Fi
                    agrees that they are aware of the risks involved, understand that they
                    could lose all of their funds, and will not hold Spool/SpoolDAO/Spool
                    Labs or those associated liable for any losses or hardship. Please
                    help to keep Spool safe by going through the code and participating in
                    the bug bounty program.
                </p>
            </CollapsibleArea>
        </div>
    );
};

export default RiskDisclaimer;
