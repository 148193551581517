import React, { ReactNode, useContext } from 'react';
import themeContext from '../context/theme/themeContext';
import useHeight from '../hooks/useHeight';

type Props = {
    children: ReactNode;
    className?: string;
    container?: boolean;
    useMinHeight?: boolean;
};

const Cover: React.FC<Props> = ({
    children,
    className = '',
    container = true,
    useMinHeight = false,
}) => {
    const { theme } = useContext(themeContext);

    const windowHeight = useHeight();

    return (
        <section id='cover' className={`${theme} ${className}`}>
            {container ? (
                <div
                    className='container'
                    style={{
                        minHeight: useMinHeight ? `${windowHeight - 346}px` : undefined,
                    }}
                >
                    {children}
                </div>
            ) : (
                children
            )}
        </section>
    );
};

export default Cover;
