import React from 'react';

const FooterLogo = () => {
    return (
        <svg
            width='77'
            height='116'
            viewBox='0 0 77 116'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M64.5334 0.633485C64.6695 1.79456 64.4605 2.97 63.9327 4.01307C63.4048 5.05613 62.5814 5.92061 61.5653 6.49857L5.84698 38.1159C3.18623 39.6233 1.23326 42.126 0.417672 45.0733C-0.397916 48.0206 -0.00930594 51.1712 1.49801 53.832L9.39166 67.7616L11.1711 70.9077L12.3456 70.2457C12.2121 69.0861 12.4231 67.9128 12.9522 66.8723C13.4813 65.8318 14.3051 64.9702 15.3208 64.3949L71.0747 32.806C72.3922 32.0597 73.5498 31.061 74.4812 29.8671C75.4125 28.6732 76.0995 27.3074 76.5027 25.8478C76.906 24.3882 77.0176 22.8635 76.8312 21.3607C76.6448 19.858 76.1641 18.4067 75.4165 17.0899L67.53 3.16743L65.7363 0L64.5334 0.633485Z'
                fill='#23B3E5'
            />
            <path
                d='M64.6544 45.0345C64.7879 46.1942 64.5769 47.3674 64.0478 48.4079C63.5187 49.4484 62.6949 50.3101 61.6792 50.8854L5.92535 82.4885C3.26844 83.9958 1.31824 86.4959 0.502863 89.4398C-0.312515 92.3837 0.0735758 95.5308 1.57638 98.1903L9.47003 112.113L11.2637 115.28L12.431 114.618C12.295 113.457 12.5039 112.282 13.0317 111.239C13.5596 110.196 14.383 109.331 15.3992 108.753L71.153 77.1643C73.8138 75.6569 75.7668 73.1543 76.5824 70.2069C77.3979 67.2596 77.0093 64.109 75.502 61.4482L67.6084 47.5186L65.8289 44.3726L64.6544 45.0345Z'
                fill='#23B3E5'
            />
        </svg>
    );
};

export default FooterLogo;
