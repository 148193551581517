import { Socials } from './constants';

export const config = {
    daoPerformanceFees: 10,
    spoolAddresses: {
        spoolToken: '0x40803cea2b2a32bda1be61d3604af6a814e70976',
        staking: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
        controller: '0xdd4051c3571c143b989c3227e8eb50983974835c',
        spoolOwnerMultiSig: '0x4e736b96920a0f305022cbaaea493ce7e49eee6c',
    },
    socialMedias: {
        [Socials.DISCORD]: 'https://discord.gg/kCDC7BWxrw',
        [Socials.TELEGRAM]: 'https://t.me/SpoolFi',
        [Socials.TWITTER]: 'https://twitter.com/spoolfi',
        [Socials.MEDIUM]: 'https://medium.com/spoolfi',
        [Socials.LINKEDIN]: 'https://www.linkedin.com/company/spooldao/',
        [Socials.GITBOOK]: 'https://docs.spool.fi/',
    },
    urls: {
        spoolApp: 'https://app.spool.fi',
        spoolVault: 'https://vault.spool.fi',
        etherscan: 'https://etherscan.io',
        sdk: 'https://www.npmjs.com/package/@spool.fi/spool-sdk',
        spoolPath: 'app/spool', // used for routing and when users copy individual URLs to share
    },
};

export const SDK_CONFIG_PRODUCTION = {
    analytics: {
        ANALYTICS_API_BASE_URL: 'https://analytics.spool.fi/',
    },
    subgraph: {
        SUBGRAPH_API_BASE_URL: 'https://api.thegraph.com/subgraphs/name/spoolfi/spool',
        UNISWAP_SUBGRAPH_API_BASE_URL:
            'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
        default_limit: 100,
    },
    smartContracts: {
        controller: '0xdd4051c3571c143b989c3227e8eb50983974835c',
        'SPOOL-ERC20': '0x40803cea2b2a32bda1be61d3604af6a814e70976',
        spoolStaking: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
        voSpool: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
        voSpoolRewards: '0xb8d5fc11f8e1af30578db9f77174a1dc171a7d28',
        multicallv2: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
        feeHandler: '0x3d3d0a7876d18770a21a5ea05fef211eba808e72',
    },
};

export const SDK_CONFIG_DEVELOPMENT = {
    analytics: {
        ANALYTICS_API_BASE_URL: 'https://spoolanalytics-dev.solidant.io',
    },
    subgraph: {
        SUBGRAPH_API_BASE_URL:
            'https://subgraph.spool-test.solidant.io/subgraphs/name/spool/subgraph',
        UNISWAP_SUBGRAPH_API_BASE_URL:
            'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2',
        default_limit: 100,
    },
    smartContracts: {
        controller: '0xdd4051c3571c143b989c3227e8eb50983974835c',
        'SPOOL-ERC20': '0x40803cea2b2a32bda1be61d3604af6a814e70976',
        spoolStaking: '0xc3160c5cc63b6116dd182faa8393d3ad9313e213',
        voSpool: '0xaf56d16a7fe479f2fcd48ff567ff589cb2d2a0e9',
        voSpoolRewards: '0xb8d5fc11f8e1af30578db9f77174a1dc171a7d28',
        multicallv2: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
        feeHandler: '0x3d3d0a7876d18770a21a5ea05fef211eba808e72',
    },
};
