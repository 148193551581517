import React from 'react';

const NavLogo = () => {
    return (
        <svg
            id='spool-logo'
            width='29'
            height='46'
            viewBox='0 0 29 46'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M24.3048 0.252778C24.356 0.71608 24.2773 1.18511 24.0785 1.60133C23.8797 2.01754 23.5696 2.36249 23.1869 2.59311L2.20211 15.2093C1.20001 15.8108 0.464474 16.8094 0.157305 17.9855C-0.149864 19.1616 -0.00350483 20.4187 0.564185 21.4805L3.53712 27.0388L4.2073 28.2941L4.64962 28.03C4.59936 27.5673 4.67883 27.0991 4.87811 26.6839C5.07738 26.2687 5.38764 25.9249 5.77017 25.6953L26.7684 13.0905C27.2646 12.7927 27.7006 12.3942 28.0513 11.9178C28.4021 11.4414 28.6608 10.8964 28.8127 10.314C28.9646 9.73157 29.0066 9.12316 28.9364 8.52353C28.8662 7.92389 28.6852 7.34479 28.4036 6.81933L25.4333 1.26389L24.7578 0L24.3048 0.252778Z'
                fill='#23B3E5'
            />
            <path
                d='M24.3503 17.97C24.4005 18.4327 24.3211 18.9008 24.1218 19.316C23.9225 19.7312 23.6123 20.075 23.2297 20.3046L2.23155 32.9151C1.23089 33.5166 0.496404 34.5142 0.189314 35.6889C-0.117776 36.8636 0.0276343 38.1194 0.593624 39.1806L3.56656 44.7361L4.2421 46L4.68174 45.7358C4.63049 45.2725 4.70918 44.8035 4.90798 44.3873C5.10678 43.9711 5.4169 43.6261 5.79961 43.3955L26.7978 30.7906C27.7999 30.1891 28.5354 29.1905 28.8426 28.0145C29.1498 26.8384 29.0034 25.5812 28.4357 24.5195L25.4628 18.9612L24.7926 17.7058L24.3503 17.97Z'
                fill='#23B3E5'
            />
        </svg>
    );
};

export default NavLogo;
