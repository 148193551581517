import React, { useContext, useEffect, useState } from 'react';
import themeContext from '../../context/theme/themeContext';
import SpoolLogo from '../../assets/logos/SpoolLogo';
import AccountDetails from './AccountDetails';
import Dropdown from '../../components/Dropdown';
import ClickAwayListener from 'react-click-away-listener';
import { formatAmountWithSuffix } from '../../helpers/formats';
import useTotalSpoolTvr from '../../hooks/web3/general/useTotalSpoolTvr';
import { NavLink, useLocation } from 'react-router-dom';
import { isActiveRoute } from '../../routes';

const navLinks = [
    {
        to: '/',
        label: 'Your Vault',
    },
];

const Navbar: React.FC = () => {
    const { theme } = useContext(themeContext);

    const [sticky, setSticky] = useState(false);
    const [open, setOpen] = useState(false);

    const location = useLocation();

    const { totalSpoolTvr } = useTotalSpoolTvr();

    const hide = () => {
        if (!open) return;

        setOpen(false);
    };

    useEffect(() => {
        const scrollListener = () => {
            if (window.scrollY > 10) {
                setSticky(true);
                return;
            }
            setSticky(false);
        };

        window.addEventListener('scroll', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
        };
    }, []);

    return (
        <header className={`${theme} ${sticky ? 'sticky' : ''}`}>
            <div className='header-container'>
                <div className='container'>
                    <a href='https://www.spool.fi/' className='logo-container'>
                        <SpoolLogo />
                    </a>
                    <ClickAwayListener onClickAway={hide}>
                        <nav>
                            <Dropdown open={open}>
                                {navLinks.map(({ to, label }) => (
                                    <NavLink
                                        exact
                                        activeClassName='active'
                                        className={
                                            isActiveRoute(location.pathname, to)
                                                ? 'active'
                                                : undefined
                                        }
                                        to={to}
                                        key={label}
                                    >
                                        {label}
                                    </NavLink>
                                ))}
                            </Dropdown>

                            <div className='total-spool'>
                                <p>
                                    Total Spool TVR{' '}
                                    <span>
                                        ${formatAmountWithSuffix(totalSpoolTvr || 0)}
                                    </span>
                                </p>
                            </div>
                        </nav>
                    </ClickAwayListener>
                    <AccountDetails />
                </div>
            </div>
        </header>
    );
};

export default Navbar;
