import { useWeb3React } from '@web3-react/core';
import { useContext } from 'react';
import useSWR from 'swr';
import { config } from '../../../config/config';
import appContext from '../../../context/app/appContext';
import { isSameAddress } from '../../../web3/utils';

const useIsCanStakeFor = () => {
    const { account } = useWeb3React();

    const { sdk } = useContext(appContext);

    const { data, mutate, error } = useSWR(
        sdk ? ['isCanStakeFor', account] : null,
        async (_, userAddress: string) => {
            if (isSameAddress(userAddress, config.spoolAddresses.spoolOwnerMultiSig)) {
                return true;
            }

            const stakingContract = await sdk!.get.getSpoolStakingContractInstance();
            const canStakeFor = await stakingContract.canStakeFor(userAddress);
            return canStakeFor;
        }
    );
    return {
        isCanStakeFor: data,
        isLoading: !error && data === (null || undefined),
        mutate,
    };
};

export default useIsCanStakeFor;
