import React, { memo } from 'react';
import { isString } from '../../helpers/validations';
import useBasePath from '../../hooks/useBasePath';
import { TabContentProps } from './tabTypes';

const TabContent: React.FC<TabContentProps> = ({ children, to }) => {
    const basePath = useBasePath();

    const isCorrectPath = isString(to) ? basePath === to : to.includes(basePath);

    return isCorrectPath ? <>{children}</> : null;
};

export default memo(TabContent);
