const LightTheme = () => (
    <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='light-icon'
    >
        <circle cx='16' cy='16' r='13' fill='white' />
        <circle cx='16' cy='16' r='5' fill='#21ABDF' />
        <rect x='15' y='4.99951' width='2' height='4' rx='1' fill='#21ABDF' />
        <rect x='15' y='22.9995' width='2' height='4' rx='1' fill='#21ABDF' />
        <rect
            x='5'
            y='16.9995'
            width='2'
            height='4'
            rx='1'
            transform='rotate(-90 5 16.9995)'
            fill='#21ABDF'
        />
        <rect
            x='23'
            y='16.9995'
            width='2'
            height='4'
            rx='1'
            transform='rotate(-90 23 16.9995)'
            fill='#21ABDF'
        />
        <rect
            x='7.51465'
            y='8.92822'
            width='2'
            height='4'
            rx='1'
            transform='rotate(-45 7.51465 8.92822)'
            fill='#21ABDF'
        />
        <rect
            x='20.2422'
            y='21.6562'
            width='2'
            height='4'
            rx='1'
            transform='rotate(-45 20.2422 21.6562)'
            fill='#21ABDF'
        />
        <rect
            x='8.92871'
            y='24.4849'
            width='2'
            height='4'
            rx='1'
            transform='rotate(-135 8.92871 24.4849)'
            fill='#21ABDF'
        />
        <rect
            x='21.6562'
            y='11.7568'
            width='2'
            height='4'
            rx='1'
            transform='rotate(-135 21.6562 11.7568)'
            fill='#21ABDF'
        />
    </svg>
);

export default LightTheme;
