const Telegram = () => (
    <svg
        width='32'
        height='27'
        viewBox='0 0 32 27'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='telegram'
    >
        <path
            d='M12.5565 17.2948L12.0272 24.4423C12.7845 24.4423 13.1126 24.13 13.5059 23.7549L17.0566 20.4974L24.4142 25.6698C25.7635 26.3917 26.7142 26.0115 27.0782 24.4781L31.9077 2.75422L31.909 2.75294C32.337 0.838085 31.1877 0.0892946 29.873 0.559049L1.48561 10.9922C-0.451774 11.7141 -0.42244 12.7509 1.15626 13.2207L8.41377 15.3877L25.2715 5.26171C26.0649 4.7574 26.7862 5.03644 26.1929 5.54075L12.5565 17.2948Z'
            fill='#23B3E5'
        />
    </svg>
);

export default Telegram;
