import React, { useContext } from 'react';
import FooterLogo from '../../assets/logos/FooterLogo';
import themeContext from '../../context/theme/themeContext';
import { config } from '../../config/config';
import { FooterArrowIcon, SocialIcons } from '../../assets/icons';
import { isActiveRoute } from '../../routes';
import { NavLink, useLocation } from 'react-router-dom';

const footerLinks = [
    {
        to: '/',
        label: 'Your Vault',
    },
];

const Footer: React.FC = () => {
    const { theme } = useContext(themeContext);

    const location = useLocation();

    return (
        <footer className={theme}>
            <div className='container'>
                <div className='logo-container'>
                    <FooterLogo />
                    <div className='details'>
                        <h4>Spool</h4>
                    </div>
                </div>
                <div className='links-container'>
                    <div className='links'>
                        {footerLinks.map(({ to, label }) => (
                            <NavLink
                                exact
                                activeClassName='active'
                                className={
                                    isActiveRoute(location.pathname, to)
                                        ? 'active'
                                        : undefined
                                }
                                to={to}
                                key={label}
                            >
                                <FooterArrowIcon />
                                <span>{label}</span>
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className='social-media-container'>
                    <p>
                        <span>&copy;</span> {new Date().getFullYear()} Spool. <br /> All
                        rights reserved.
                    </p>
                    <div className='icons-container'>
                        {Object.entries(config.socialMedias).map(([name, link]) => (
                            <a
                                key={name}
                                href={link}
                                target='_blank'
                                rel='noreferrer noopener'
                            >
                                {SocialIcons[name]}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
