import { useContext } from 'react';
import useSWR from 'swr';
import appContext from '../../../context/app/appContext';

const useTotalSpoolTvr = () => {
    const { sdk } = useContext(appContext);

    const { data, error } = useSWR(sdk ? 'totalSpoolTvr' : null, () =>
        sdk!.get.getTotalSpoolsTvr()
    );

    return {
        totalSpoolTvr: data,
        isLoading: !error && !data,
        error,
    };
};

export default useTotalSpoolTvr;
