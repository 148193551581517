import React, { useContext } from 'react';
import modalContext from '../../context/modal/modalContext';
import CustomModal from '../../components/CustomModal';
import { SpinnerWaitingIcon } from '../../assets/icons';

const WaitingModal: React.FC = () => {
    const { modalData } = useContext(modalContext);

    return (
        <CustomModal
            modalName='waitingModal'
            className='waiting-modal'
            overlayClassName='waiting-modal'
            shouldCloseOnOverlayClick={false}
        >
            <div className='status'>Please Wait!</div>
            <div className='spinner'>
                <div className='outer-circle' />
                <div className='track' />
                <SpinnerWaitingIcon />
                <div className='inner-circle' />
                {modalData?.step ? <div className='text'>{modalData.step}/2</div> : null}
            </div>
            <div
                className='message'
                dangerouslySetInnerHTML={{ __html: modalData?.message }}
            />
        </CustomModal>
    );
};

export default WaitingModal;
