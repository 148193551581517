import { formatEther, parseEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import { useContext, useMemo } from 'react';
import Button from '../../../../components/Button';
import { config } from '../../../../config/config';
import {
    APPROVAL_PENDING,
    INSUFFICIENT_FUNDS_ERROR,
    STAKE_CONFIRMED,
    STAKE_PENDING,
} from '../../../../config/constants';
import appContext from '../../../../context/app/appContext';
import errorContext from '../../../../context/error/errorContext';
import modalContext from '../../../../context/modal/modalContext';
import useUserBalance from '../../../../hooks/web3/useUserBalance';
import { calculateMargin, sleep, toBigNumber } from '../../../../web3/utils';
import { Contract } from 'ethers';
import { MaxUint256 } from '@ethersproject/constants';
import abis from '../../../../config/abis';
import useIsApproved from '../../../../hooks/web3/useIsApproved';
import useIsCanStakeFor from '../../../../hooks/web3/vault/useIsCanStakeFor';
import useIsAllowedToStakeForUser from '../../../../hooks/web3/vault/useIsAllowedToStakeForUser';

type StakeOnBehalfButtonsProps = {
    amount: string;
    stakeForAddress: string;
    setAmount: React.Dispatch<React.SetStateAction<string>>;
    setStakeForAddress: React.Dispatch<React.SetStateAction<string>>;
};

const StakeOnBehalfButtons: React.FC<StakeOnBehalfButtonsProps> = ({
    amount,
    stakeForAddress,
    setAmount,
    setStakeForAddress,
}) => {
    const { library } = useWeb3React();

    const { sdk } = useContext(appContext);

    const { setError } = useContext(errorContext);

    const { openModal, closeModal, setModalData } = useContext(modalContext);

    const { isCanStakeFor } = useIsCanStakeFor();

    const { isAllowedToStakeForUser } = useIsAllowedToStakeForUser(stakeForAddress);

    const userBalance = useUserBalance(config.spoolAddresses.spoolToken);

    const { isApproved, mutate } = useIsApproved(
        config.spoolAddresses.spoolToken,
        config.spoolAddresses.staking,
        amount
    );

    const isAllowedToStake = useMemo(
        () => isCanStakeFor && isAllowedToStakeForUser,
        [isCanStakeFor, isAllowedToStakeForUser]
    );

    const disableButtonCondition =
        !amount ||
        parseInt(amount) > parseInt(formatEther(userBalance!)) ||
        !isAllowedToStake ||
        !isCanStakeFor ||
        !stakeForAddress;

    const approve = async () => {
        try {
            if (!library) return;

            setModalData((prev) => ({
                ...prev,
                message: APPROVAL_PENDING,
            }));
            openModal('waitingModal');

            const contract = new Contract(
                config.spoolAddresses.spoolToken,
                abis.erc20,
                library.getSigner()
            );

            let useExact = false;

            const estimatedGas = await contract.estimateGas
                .approve(config.spoolAddresses.staking, MaxUint256)
                .catch(() => {
                    // general fallback for tokens who restrict approval amounts
                    useExact = true;

                    return contract.estimateGas.approve(
                        config.spoolAddresses.staking,
                        parseEther(amount)
                    );
                });

            const approve = await contract.approve(
                config.spoolAddresses.staking,
                useExact ? parseEther(amount) : MaxUint256,
                {
                    gasLimit: calculateMargin(estimatedGas),
                }
            );

            await approve.wait();

            await mutate();
        } catch (error) {
            setError(error);
        } finally {
            closeModal('waitingModal');
        }
    };

    const stake = async () => {
        try {
            if (!sdk) return;

            if (userBalance.lt(toBigNumber(amount))) {
                openModal('errorModal');
                setModalData((prev) => ({
                    ...prev,
                    message: INSUFFICIENT_FUNDS_ERROR,
                }));
                return;
            }

            setModalData((prev) => ({ ...prev, message: STAKE_PENDING }));

            openModal('waitingModal');

            const stakingContract = await sdk.get.getSpoolStakingContractInstance();

            const stakeFor = await stakingContract.stakeFor(
                stakeForAddress,
                parseEther(amount)
            );

            const receipt = await stakeFor.wait();

            await sleep(5000);

            setModalData((prev) => ({
                ...prev,
                status: STAKE_CONFIRMED,
                txHash: receipt.transactionHash,
                message: '',
            }));
            openModal('successModal');
            setAmount('');
            setStakeForAddress('');
        } catch (error) {
            setError(error);
        } finally {
            closeModal('waitingModal');
        }
    };

    return (
        <>
            {isApproved ? (
                <Button onClick={stake} disabled={disableButtonCondition}>
                    Stake For
                </Button>
            ) : (
                <Button onClick={approve} disabled={!isCanStakeFor}>
                    Approve
                </Button>
            )}
        </>
    );
};

export default StakeOnBehalfButtons;
