const Twitter = () => (
    <svg
        width='30'
        height='24'
        viewBox='0 0 30 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='twitter'
    >
        <path
            d='M26.5057 5.99353C26.5185 6.25273 26.5233 6.51513 26.5233 6.77433C26.5233 14.7583 20.4481 23.9615 9.33928 23.9615C5.92648 23.9615 2.75368 22.9615 0.0800781 21.2463C0.553678 21.3023 1.03368 21.3311 1.52008 21.3311C4.35208 21.3311 6.95528 20.3647 9.02088 18.7471C6.37928 18.6975 4.14728 16.9535 3.37928 14.5535C3.74728 14.6223 4.12648 14.6591 4.51528 14.6591C5.06728 14.6591 5.60168 14.5871 6.10728 14.4495C3.34408 13.8927 1.26248 11.4527 1.26248 8.52473C1.26248 8.49913 1.26248 8.47513 1.26248 8.44953C2.07688 8.90233 3.00968 9.17433 3.99848 9.20633C2.37768 8.12153 1.31048 6.27513 1.31048 4.17753C1.31048 3.07193 1.60808 2.03353 2.12968 1.14073C5.10728 4.79673 9.56008 7.19993 14.5793 7.45273C14.4769 7.00953 14.4241 6.55033 14.4241 6.07513C14.4241 2.74073 17.1265 0.0383301 20.4625 0.0383301C22.2001 0.0383301 23.7697 0.76953 24.8721 1.94393C26.2465 1.67193 27.5393 1.16953 28.7073 0.47833C28.2561 1.88793 27.2977 3.07193 26.0513 3.81913C27.2737 3.67193 28.4353 3.35033 29.5201 2.86873C28.7105 4.08153 27.6865 5.14393 26.5057 5.99353Z'
            fill='#23B3E5'
        />
    </svg>
);

export default Twitter;
