import { TabBody, TabContent, Tabs } from '../../components/Tabs';

import StakeOnBehalf from './StakeOnBehalf';

const Vault: React.FC = () => {
    return (
        <Tabs>
            <TabBody>
                <TabContent to='/'>
                    <StakeOnBehalf />
                </TabContent>
            </TabBody>
        </Tabs>
    );
};

export default Vault;
