import { formatEther } from '@ethersproject/units';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/TextInput';
import { formatAccount, formatUSD } from '../../../../helpers/formats';
import useIsCanStakeFor from '../../../../hooks/web3/vault/useIsCanStakeFor';
import { preventOverflow } from '../../../../web3/utils';
import SpoolLogo from '../../../../assets/logos/SpoolLogo';

type StakeOnBehalfInputsProps = {
    amount: string;
    userBalance: string;
    stakeForAddress: string;
    setAmount: React.Dispatch<React.SetStateAction<string>>;
    setStakeForAddress: React.Dispatch<React.SetStateAction<string>>;
};

const StakeOnBehalfInputs: React.FC<StakeOnBehalfInputsProps> = ({
    amount,
    userBalance,
    stakeForAddress,
    setAmount,
    setStakeForAddress,
}) => {
    const { isCanStakeFor } = useIsCanStakeFor();

    const handleMaxInput = () => {
        setAmount(formatEther(userBalance));
    };

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setStakeForAddress(value);
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setAmount(preventOverflow(value));
    };
    return (
        <>
            <div className='field-container input'>
                <span className='field'>Stake For Address</span>
                <div className='input-container'>
                    <TextInput
                        variant={'text'}
                        value={stakeForAddress}
                        onChange={handleAddressChange}
                        name='stakeForAddress'
                        placeholder={formatAccount('0x00000000000')}
                        disabled={!isCanStakeFor}
                    />
                    <div className='ticker'>
                        <SpoolLogo />
                        <span>SPOOL</span>
                    </div>
                </div>
            </div>
            <div className='field-container input'>
                <span className='field'>Amount of Spool</span>
                <div className='input-container'>
                    <div className='input-with-button'>
                        <TextInput
                            variant={'number'}
                            value={amount}
                            onChange={handleAmountChange}
                            name='amount'
                            placeholder='0.00'
                            disabled={!isCanStakeFor}
                        />
                        <Button onClick={handleMaxInput} variant='secondary'>
                            MAX
                        </Button>
                    </div>
                    <div className='spool-amount-extras'>
                        <div className='field-container balance'>
                            <span className='field'>Spool in Wallet</span>
                            <span>{formatUSD(formatEther(userBalance) || 0, 4)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StakeOnBehalfInputs;
