export enum SupportedChains {
    MAINNET = 1,
    ROPSTEN = 3,
    RINKEBY = 4,
    GOERLI = 5,
    KOVAN = 42,
}

export enum SupportedConnectors {
    METAMASK = 'Metamask',
    WALLET_CONNECT = 'WalletConnect',
    COINBASE = 'Coinbase Wallet',
    TREZOR = 'Trezor',
    PORTIS = 'Portis',
    LEDGER = 'Ledger',
    TORUS = 'Torus',
    FORTMATIC = 'Formatic',
}

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
}

export enum Socials {
    DISCORD = 'DISCORD',
    TWITTER = 'TWITTER',
    TELEGRAM = 'TELEGRAM',
    MEDIUM = 'MEDIUM',
    GITBOOK = 'GITBOOK',
    LINKEDIN = 'LINKEDIN',
}

// STATUS
export const APPROVAL_PENDING = 'Please wait as your Approval is being Confirmed.';

// STAKING
export const STAKE_PENDING = 'Please wait while your SPOOL is Staked.';
export const STAKE_CONFIRMED = 'Your SPOOL has been Staked. <br />Welcome to Spool DAO';

// ERROR`
export const INSUFFICIENT_FUNDS_ERROR = 'Insufficient Funds';
