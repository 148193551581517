import './css/App.scss';
import Navbar from './layout/Header/Navbar';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './layout/Footer/Footer';
import ModalState from './context/modal/ModalState';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import ConnectionState from './context/connection/ConnectionState';
import ModalsContainer from './modals/ModalsContainer';
import AppState from './context/app/AppState';
import ErrorState from './context/error/ErrorState';
import ScrollToTop from './layout/ScrollToTop';
import ThemeState from './context/theme/ThemeState';
import Maintenance from './pages/Maintenance';
import Routes from './routes';
import { SWRConfig } from 'swr';

function App() {
    const getLibrary = (provider: any): Web3Provider => {
        const library = new Web3Provider(provider);
        library.pollingInterval = 12000;

        return library;
    };

    return (
        <Router>
            <ScrollToTop />
            <SWRConfig
                value={{
                    errorRetryCount: 5,
                    dedupingInterval: 60000,
                }}
            >
                <ThemeState>
                    <ModalState>
                        <ErrorState>
                            <Web3ReactProvider getLibrary={getLibrary}>
                                <AppState>
                                    <ConnectionState>
                                        <Maintenance
                                            mode={false}
                                            development={false}
                                            developmentText='DEV Spool Stake On Behalf'
                                        >
                                            <Navbar />
                                            <Routes />
                                            <Footer />
                                            <ModalsContainer />
                                        </Maintenance>
                                    </ConnectionState>
                                </AppState>
                            </Web3ReactProvider>
                        </ErrorState>
                    </ModalState>
                </ThemeState>
            </SWRConfig>
        </Router>
    );
}

export default App;
