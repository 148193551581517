const Medium = () => (
    <svg
        width='30'
        height='24'
        viewBox='0 0 30 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='medium'
    >
        <path
            d='M29.0005 4.00002C29.0005 4.00002 27.8001 4.00002 27.8001 5.20023C27.8001 6.40045 27.8001 18.8005 27.8001 18.8005C27.8001 18.8005 27.8001 19.9999 29.0005 19.9999H29.4001V23.1998H19.8001V19.9999H21.4001V5.11993H21.2401L16.3368 23.1998H12.0187L7.16006 5.11993H7.0001V19.9999H8.6001V23.1998H0.600098V19.9999H0.999718C0.999718 19.9999 2.2001 19.9999 2.2001 18.7996C2.2001 17.5994 2.2001 5.19933 2.2001 5.19933C2.2001 5.19933 2.2001 4.00002 0.999718 4.00002H0.600098V0.800049H11.2146L14.9376 14.6546H15.0603L18.8177 0.800049H29.4001V4.00002H29.0005Z'
            fill='#23B3E5'
        />
    </svg>
);

export default Medium;
