import { useContext } from 'react';
import { ConnectWalletIcon } from '../assets/icons';
import modalContext from '../context/modal/modalContext';
import Cover from './Cover';

const ConnectWalletFallBack: React.FC = () => {
    const { openModal } = useContext(modalContext);

    const handleClick = () => {
        openModal('connectWalletModal');
    };

    return (
        <Cover className='connect-wallet-fallback'>
            <div className='connect-wallet-prompt' onClick={handleClick}>
                <ConnectWalletIcon />
                <p>Connect Wallet to View Your Vault</p>
            </div>
        </Cover>
    );
};

export default ConnectWalletFallBack;
