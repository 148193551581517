import { config } from '../../../config/config';
import { ReactNode, useContext, useEffect, useState } from 'react';
import themeContext from '../../../context/theme/themeContext';
import useUserBalance from '../../../hooks/web3/useUserBalance';
import Cover from '../../../layout/Cover';
import { useWeb3React } from '@web3-react/core';
import StakeOnBehalfButtons from './StakeOnBehalfButtons';
import StakeOnBehalfInputs from './StakeOnBehalfInputs';
import StakeOnBehalfWarnings from './StakeOnBehalfWarnings';

const StakeOnBehalf: React.FC<ReactNode> = () => {
    const { account } = useWeb3React();

    const { theme } = useContext(themeContext);

    const [stakeForAddress, setStakeForAddress] = useState('');

    const [amount, setAmount] = useState('');

    const userBalance = useUserBalance(config.spoolAddresses.spoolToken);

    useEffect(() => {
        setAmount('');
        setStakeForAddress('');
    }, [account]);

    return (
        <div className={`vault-tab summary ${theme}`}>
            <Cover useMinHeight={true}>
                <div className='inner__left'>
                    <h2>Stake on Behalf</h2>
                </div>
                <div className='inner__right'>
                    <StakeOnBehalfInputs
                        amount={amount}
                        setAmount={setAmount}
                        stakeForAddress={stakeForAddress}
                        setStakeForAddress={setStakeForAddress}
                        userBalance={userBalance}
                    />
                    <StakeOnBehalfButtons
                        amount={amount}
                        setAmount={setAmount}
                        stakeForAddress={stakeForAddress}
                        setStakeForAddress={setStakeForAddress}
                    />
                    <StakeOnBehalfWarnings
                        stakeForAddress={stakeForAddress}
                        amount={amount}
                        userBalance={userBalance}
                    />
                </div>
            </Cover>
        </div>
    );
};

export default StakeOnBehalf;
