const Gitbook = () => (
    <svg
        width='26'
        height='19'
        viewBox='0 0 26 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='gitbook'
    >
        <path
            d='M11.362 15.1917C11.7564 15.1917 12.1113 15.5071 12.1113 15.9409C12.1113 16.3352 11.7958 16.6901 11.362 16.6901C10.9677 16.6901 10.6128 16.3747 10.6128 15.9409C10.6128 15.5071 10.9677 15.1917 11.362 15.1917ZM22.9554 10.6174C22.5611 10.6174 22.2062 10.3019 22.2062 9.86816C22.2062 9.47382 22.5217 9.11892 22.9554 9.11892C23.3498 9.11892 23.7047 9.43439 23.7047 9.86816C23.7047 10.2625 23.3498 10.6174 22.9554 10.6174ZM22.9554 7.58102C21.6936 7.58102 20.6683 8.60629 20.6683 9.86816C20.6683 10.1048 20.7077 10.3414 20.7866 10.578L13.2548 14.6002C12.8211 13.9692 12.1113 13.6143 11.362 13.6143C10.4945 13.6143 9.70581 14.127 9.31148 14.8762L2.52893 11.3272C1.81913 10.9329 1.26706 9.78929 1.34593 8.68515C1.38536 8.13309 1.58253 7.69932 1.85856 7.54158C2.05573 7.42328 2.2529 7.46272 2.52893 7.58102L2.56836 7.62045C4.3823 8.56685 10.2579 11.6427 10.4945 11.761C10.8888 11.9187 11.086 11.9976 11.7564 11.6821L23.9018 5.37274C24.099 5.29388 24.2962 5.13614 24.2962 4.86011C24.2962 4.50521 23.9413 4.34747 23.9413 4.34747C23.2315 4.03201 22.1668 3.51937 21.1415 3.04617C18.9332 2.0209 16.4095 0.837899 15.3054 0.246398C14.359 -0.266237 13.5703 0.167531 13.452 0.246398L13.176 0.364698C8.16791 2.88844 1.54309 6.16141 1.14876 6.39801C0.478391 6.79235 0.0446232 7.62045 0.00518979 8.64572C-0.0736771 10.2625 0.754425 11.9581 1.93743 12.5496L9.11431 16.2564C9.27205 17.3605 10.2579 18.228 11.362 18.228C12.6239 18.228 13.6097 17.2422 13.6492 15.9803L21.5358 11.7215C21.9302 12.037 22.4428 12.1947 22.9554 12.1947C24.2173 12.1947 25.2426 11.1695 25.2426 9.90759C25.2426 8.60629 24.2173 7.58102 22.9554 7.58102Z'
            fill='#23B3E5'
        />
    </svg>
);

export default Gitbook;
