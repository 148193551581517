const DarkTheme = () => (
    <svg
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='dark-icon'
    >
        <circle cx='13' cy='13' r='13' fill='#23B3E5' />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.4839 16.2454C19.8506 16.4039 19.1878 16.4881 18.5053 16.4881C14.02 16.4881 10.384 12.8521 10.384 8.36677C10.384 7.16579 10.6447 6.02571 11.1125 5C7.58349 5.88342 4.96973 9.07579 4.96973 12.8786C4.96973 17.3639 8.60578 21 13.0911 21C16.3754 21 19.2044 19.0504 20.4839 16.2454Z'
            fill='#16191D'
        />
    </svg>
);
export default DarkTheme;
