import { parseEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import { ethers, utils } from 'ethers';
import { useContext } from 'react';
import appContext from '../../../context/app/appContext';
import { config } from '../../../config/config';
import { isSameAddress } from '../../../web3/utils';
import useSWR from 'swr';

const useIsAllowedToStakeForUser = (address: string | undefined) => {
    const { sdk } = useContext(appContext);
    const { account } = useWeb3React();

    const { data, error, mutate } = useSWR(
        sdk ? ['isAllowedToStakeForUser', address] : null,
        async (_, userAddress: string) => {
            if (!userAddress) {
                return true;
            }

            if (!utils.isAddress(userAddress)) {
                return false;
            }
            const stakedSpool = await sdk!.get.getStakedSpool(userAddress);

            if (!parseEther(stakedSpool).isZero()) {
                const stakingContract = sdk!.get.getSpoolStakingContractInstance();
                const stakedBy = await stakingContract.stakedBy(userAddress);
                if (stakedBy === ethers.constants.AddressZero) {
                    return true;
                }
                if (
                    isSameAddress(stakedBy, account!) ||
                    isSameAddress(account!, config.spoolAddresses.spoolOwnerMultiSig)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        }
    );
    return {
        isAllowedToStakeForUser: data ?? false,
        isLoading: !error && data === (null || undefined),
        mutate,
    };
};

export default useIsAllowedToStakeForUser;
