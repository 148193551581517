const Wallet = () => {
    return (
        <svg
            width='36'
            height='32'
            viewBox='0 0 36 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M32.8064 23.8278V27.9999C32.8064 29.6567 31.4633 30.9999 29.8064 30.9999H4C2.34314 30.9999 1 29.6567 1 27.9999V3.87085V4.51011C1 6.16696 2.34315 7.51011 4 7.51011H29.8064C31.4633 7.51011 32.8064 8.85325 32.8064 10.5101V13.3826'
                stroke='#23B3E5'
            />
            <path
                d='M1 19.0707L0.500048 19.0776L1.5 19.0707H1ZM32.806 10.4556L32.306 10.4646L33.306 10.4556H32.806ZM1.49951 3.92481C1.49951 2.54409 2.6188 1.4248 3.99951 1.4248V0.424805C2.06651 0.424805 0.499512 1.99181 0.499512 3.92481H1.49951ZM1.49951 19.0001V3.92481H0.499512V19.0001H1.49951ZM1.49995 19.0638C1.49966 19.0426 1.49951 19.0213 1.49951 19.0001H0.499512C0.499512 19.0259 0.499691 19.0518 0.500048 19.0776L1.49995 19.0638ZM1.5 19.0707V4.51029H0.5V19.0707H1.5ZM0.5 4.51029C0.5 6.44329 2.067 8.01029 4 8.01029V7.01029C2.61929 7.01029 1.5 5.89101 1.5 4.51029H0.5ZM4 8.01029H29.8064V7.01029H4V8.01029ZM29.8064 8.01029C31.1719 8.01029 32.2817 9.10505 32.306 10.4646L33.3059 10.4467C33.2719 8.54293 31.7182 7.01029 29.8064 7.01029V8.01029ZM32.306 3.9248V10.4556H33.306V3.9248H32.306ZM29.806 1.4248C31.1867 1.4248 32.306 2.54409 32.306 3.9248H33.306C33.306 1.99181 31.739 0.424805 29.806 0.424805V1.4248ZM3.99951 1.4248H29.806V0.424805H3.99951V1.4248Z'
                fill='#23B3E5'
            />
            <path
                d='M32.8072 11.2039V10.7039H32.3072V11.2039H32.8072ZM32.8072 14.5108V15.0108H33.3072V14.5108H32.8072ZM34.735 16.0004H34.235L34.235 16.0048L34.735 16.0004ZM32.8072 11.7039C33.5958 11.7039 34.235 12.3431 34.235 13.1317H35.235C35.235 11.7908 34.1481 10.7039 32.8072 10.7039V11.7039ZM33.3072 14.5108V11.2039H32.3072V14.5108H33.3072ZM22.8289 15.0108H32.8072V14.0108H22.8289V15.0108ZM18.6987 19.141C18.6987 16.8599 20.5479 15.0108 22.8289 15.0108V14.0108C19.9956 14.0108 17.6987 16.3077 17.6987 19.141H18.6987ZM22.8289 23.2712C20.5479 23.2712 18.6987 21.422 18.6987 19.141H17.6987C17.6987 21.9743 19.9956 24.2712 22.8289 24.2712V23.2712ZM33.2318 23.2712H22.8289V24.2712H33.2318V23.2712ZM34.2351 22.2679C34.2351 22.822 33.7859 23.2712 33.2318 23.2712V24.2712C34.3382 24.2712 35.2351 23.3742 35.2351 22.2679H34.2351ZM34.2351 16.0141V22.2679H35.2351V16.0141H34.2351ZM34.235 16.0048C34.2351 16.008 34.2351 16.0111 34.2351 16.0141H35.2351C35.2351 16.008 35.2351 16.002 35.235 15.996L34.235 16.0048ZM34.235 13.1317V16.0004H35.235V13.1317H34.235Z'
                fill='#23B3E5'
            />
            <circle cx='22.8281' cy='19.1409' r='1.48436' stroke='#23B3E5' />
        </svg>
    );
};

export default Wallet;
