import { useWeb3React } from '@web3-react/core';
import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import themeContext from '../context/theme/themeContext';
import ConnectWalletFallBack from '../layout/ConnectWalletFallback';
import Vault from '../pages/Vault';

export const vaultRoutes = ['/'];

export const isActiveRoute = (currentPath: string, pathToCompare: string) => {
    if (vaultRoutes.includes(pathToCompare) && vaultRoutes.includes(currentPath)) {
        return true;
    }
};

const Routes = () => {
    const { theme } = useContext(themeContext);

    const { account } = useWeb3React();

    return (
        <main className={theme}>
            <Switch>
                <Route
                    path={vaultRoutes}
                    exact
                    component={account ? Vault : ConnectWalletFallBack}
                />
                <Redirect to='/' />
            </Switch>
        </main>
    );
};

export default Routes;
