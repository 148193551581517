import React, { ReactNode, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import AppContext from './appContext';
import { SpoolSdk, SpoolSdkParameters } from '@spool.fi/spool-sdk';
import { SDK_CONFIG_PRODUCTION } from '../../config/config';

const AppState: React.FC<ReactNode> = ({ children }) => {
    const { library, account } = useWeb3React();
    const sdk = useMemo(
        () =>
            library
                ? new SpoolSdk(
                      account ? library.getSigner() : library,
                      SDK_CONFIG_PRODUCTION as unknown as SpoolSdkParameters
                  )
                : undefined,
        [library, account]
    );

    return (
        <AppContext.Provider
            value={{
                sdk,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppState;
