import React, { ReactNode, useCallback, useState } from 'react';
import ModalContext from './modalContext';
import { ModalDataTypes, Modals } from './modalTypes';

const ModalState: React.FC<ReactNode> = ({ children }) => {
    const initialState = {
        waitingModal: false,
        errorModal: false,
        warningModal: false,
        successModal: false,
        loadingModal: false,
        connectWalletModal: false,
    };

    const [modal, setModal] = useState<Modals>(initialState);
    const [modalData, setModalData] = useState<ModalDataTypes>({
        type: '',
        message: '',
        status: '',
        txHash: '',
        step: undefined,
    });

    const openModal = useCallback((name: keyof Modals): void => {
        setModal((prev) => ({ ...prev, [name]: true }));
    }, []);

    const closeModal = useCallback((name: keyof Modals): void => {
        setModal((prev) => ({ ...prev, [name]: false }));
    }, []);

    return (
        <ModalContext.Provider
            value={{
                modal,
                modalData,
                openModal,
                closeModal,
                setModalData,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export default ModalState;
