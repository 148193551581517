import React from 'react';
import CustomModal from '../../components/CustomModal';

const LoadingModal: React.FC = () => {
    return (
        <CustomModal modalName='loadingModal' className='loading-modal'>
            <div className='dots-container'>
                <span className='dot'></span>
                <div className='dots'>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className='loading-text'>Loading...</div>
        </CustomModal>
    );
};

export default LoadingModal;
