import { useCallback, useEffect, useRef, useState } from 'react';
import useHeight from '../../hooks/useHeight';
import useWidth from '../../hooks/useWidth';
import TabContext from './tabsContext';

const Tabs: React.FC = ({ children }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [disabled, setDisabled] = useState(false);

    const windowWidth = useWidth();
    const windowHeight = useHeight();

    const swipeableRef = useRef<any>(null);

    const updateHeight = useCallback((duration: number = 1000) => {
        const interval = setInterval(() => {
            swipeableRef.current && swipeableRef.current.updateHeight();
        }, 50);

        setTimeout(() => {
            clearInterval(interval);
        }, duration);
    }, []);

    useEffect(() => {
        updateHeight();
    }, [windowHeight, windowWidth, tabIndex, updateHeight]);

    return (
        <TabContext.Provider
            value={{
                tabIndex,
                disabled,
                swipeableRef,
                setTabIndex,
                setDisabled,
                updateHeight,
            }}
        >
            {children}
        </TabContext.Provider>
    );
};

export default Tabs;
