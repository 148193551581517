const WalletConnect = () => (
    <svg width='40' height='25' xmlns='http://www.w3.org/2000/svg' id='walletconnect'>
        <g>
            <rect
                fill='none'
                id='canvas_background'
                height='27'
                width='42'
                y='-1'
                x='-1'
            />
        </g>
        <g>
            <path
                stroke='null'
                fill='#ffffff'
                id='WalletConnect'
                d='m8.220208,5.072816c6.518033,-6.381698 17.085851,-6.381698 23.603883,0l0.784456,0.768048c0.325901,0.319085 0.325901,0.836423 0,1.155509l-2.683467,2.627337c-0.162951,0.159543 -0.427146,0.159543 -0.590097,0l-1.079504,-1.056925c-4.547142,-4.452031 -11.919517,-4.452031 -16.466659,0l-1.15606,1.13188c-0.162951,0.159542 -0.427146,0.159542 -0.590097,0l-2.683466,-2.627337c-0.325901,-0.319085 -0.325901,-0.836425 0,-1.155509l0.86101,-0.843003zm29.153546,5.433612l2.388291,2.338343c0.325899,0.319084 0.325901,0.836419 0.000004,1.155505l-10.768966,10.543882c-0.3259,0.319086 -0.854291,0.31909 -1.180194,0.000008c-0.000001,-0.000001 -0.000004,-0.000003 -0.000005,-0.000004l-7.643157,-7.4833c-0.081475,-0.079771 -0.213573,-0.079771 -0.295049,0c0,0.000001 -0.000001,0.000001 -0.000001,0.000003l-7.642994,7.483294c-0.325899,0.319088 -0.85429,0.319093 -1.180194,0.000011c-0.000003,-0.000001 -0.000004,-0.000003 -0.000007,-0.000005l-10.769266,-10.54402c-0.325901,-0.319085 -0.325901,-0.836425 0,-1.155509l2.388295,-2.33834c0.325901,-0.319085 0.854293,-0.319085 1.180194,0l7.643268,7.483404c0.081475,0.079771 0.213573,0.079771 0.295049,0c0.000001,-0.000001 0.000003,-0.000003 0.000004,-0.000004l7.642885,-7.4834c0.325893,-0.319093 0.854285,-0.319105 1.180194,-0.000027c0.000005,0.000004 0.000009,0.000009 0.000013,0.000013l7.643258,7.483413c0.081475,0.079771 0.213573,0.079771 0.295049,0l7.643135,-7.483266c0.325901,-0.319085 0.854293,-0.319085 1.180194,0z'
            />
        </g>
    </svg>
);

export default WalletConnect;
