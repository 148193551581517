import React, { memo, useContext } from 'react';
import themeContext from '../../context/theme/themeContext';
import SpoolLogo from '../../assets/logos/SpoolLogo';
import { useWeb3React } from '@web3-react/core';
import { config } from '../../config/config';
import { formatAccount, splitDecimals } from '../../helpers/formats';
import { formatUnits } from '@ethersproject/units';
import modalContext from '../../context/modal/modalContext';
import useUserBalance from '../../hooks/web3/useUserBalance';
import { DarkThemeIcon, LightThemeIcon, WalletIcon } from '../../assets/icons';

const AccountDetails: React.FC = () => {
    const { toggleTheme } = useContext(themeContext);

    const { openModal } = useContext(modalContext);

    const { account } = useWeb3React();

    const userBalance = useUserBalance(config.spoolAddresses.spoolToken);

    return (
        <div className='header__right'>
            <div className='switch-container'>
                <span>Select mode:</span>
                <div className='switch' onClick={toggleTheme}>
                    <LightThemeIcon />
                    <DarkThemeIcon />
                </div>
            </div>
            {account ? (
                <div className='spool-balance'>
                    <span>In your account :</span>
                    <div className='balance'>
                        {splitDecimals((+formatUnits(userBalance)).toFixed(2))}
                    </div>
                    <div className='ticker'>
                        <SpoolLogo />
                        <span>SPOOL</span>
                    </div>
                </div>
            ) : null}
            <div className='wallet' onClick={() => openModal('connectWalletModal')}>
                <WalletIcon />
                <span className={account ? 'connected' : ''}>
                    {account ? formatAccount(account) : 'Connect Wallet'}
                </span>
            </div>
        </div>
    );
};

export default memo(AccountDetails);
