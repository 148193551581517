import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    variant: 'text' | 'number';
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
}

const TextInput: React.FC<Props> = ({
    className,
    variant = 'text',
    onChange = () => {},
    name,
    ...rest
}) => {
    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (
            value !== '.' &&
            +value < Number.MAX_SAFE_INTEGER &&
            value.match('^[0-9]*[.]?[0-9]*$')
        ) {
            onChange(event);
        }
    };

    return (
        <input
            type='text'
            inputMode='decimal'
            pattern='^[0-9]*[.]?[0-9]*$'
            name={name}
            onChange={variant === 'text' ? onChange : handleAmountChange}
            className={`text-input ${className}`}
            {...rest}
        />
    );
};

export default TextInput;
