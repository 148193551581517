const ConnectWallet = () => (
    <svg
        width='160'
        height='152'
        viewBox='0 0 160 152'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            opacity='0.2'
            d='M131.977 26.0844C120.777 13.5242 105.678 5.09585 89.1086 2.15455C72.5391 -0.786745 55.4618 1.93 40.6231 9.86789C25.7843 17.8058 14.0463 30.5035 7.29677 45.9192C0.547209 61.3349 -0.821712 78.5726 3.41015 94.8603C7.64201 111.148 17.2287 125.539 30.6286 135.72C44.0285 145.9 60.4629 151.278 77.289 150.989C94.115 150.7 110.355 144.76 123.397 134.125C136.439 123.49 145.526 108.778 149.195 92.3545'
            stroke='white'
        />
        <path
            d='M154.427 76.5935V89.437C154.427 91.0939 153.084 92.437 151.427 92.437H107.5M154.427 53.5193V43.5467C154.427 41.8898 153.084 40.5467 151.427 40.5467H82.001C80.3441 40.5467 79.001 39.2035 79.001 37.5467V32.5073V59.4993'
            stroke='#23B3E5'
        />
        <path
            d='M79.0012 71.1084L78.5013 71.1191L79.5012 71.1084H79.0012ZM154 42.0029L153.572 42.2606L154.5 43.8044V42.0029H154ZM79.5 28.9998C79.5 27.619 80.6193 26.4998 82 26.4998V25.4998C80.067 25.4998 78.5 27.0668 78.5 28.9998H79.5ZM79.5 70.9998V28.9998H78.5V70.9998H79.5ZM79.501 71.0978C79.5003 71.0652 79.5 71.0325 79.5 70.9998H78.5C78.5 71.0396 78.5004 71.0794 78.5013 71.1191L79.501 71.0978ZM79.5012 71.1084V37.5469H78.5012V71.1084H79.5012ZM78.5012 37.5469C78.5012 39.4799 80.0682 41.0469 82.0012 41.0469V40.0469C80.6204 40.0469 79.5012 38.9276 79.5012 37.5469H78.5012ZM82.0012 41.0469H151.427V40.0469H82.0012V41.0469ZM151.427 41.0469C152.337 41.0469 153.134 41.5323 153.572 42.2606L154.428 41.7452C153.817 40.7287 152.702 40.0469 151.427 40.0469V41.0469ZM153.5 28.9998V42.0029H154.5V28.9998H153.5ZM151 26.4998C152.381 26.4998 153.5 27.619 153.5 28.9998H154.5C154.5 27.0668 152.933 25.4998 151 25.4998V26.4998ZM82 26.4998H151V25.4998H82V26.4998Z'
            fill='#23B3E5'
        />
        <path
            d='M154.429 48.7061V48.2061H153.929V48.7061H154.429ZM154.429 56.0114V56.5114H154.929V56.0114H154.429ZM159 57.9627H158.5L158.5 57.9746L159 57.9627ZM154.429 49.2061H157V48.2061H154.429V49.2061ZM154.929 56.0114V48.7061H153.929V56.0114H154.929ZM130.014 56.5114H154.429V55.5114H130.014V56.5114ZM120.286 66.2397C120.286 60.8669 124.642 56.5114 130.014 56.5114V55.5114C124.089 55.5114 119.286 60.3146 119.286 66.2397H120.286ZM130.014 75.968C124.642 75.968 120.286 71.6125 120.286 66.2397H119.286C119.286 72.1648 124.089 76.968 130.014 76.968V75.968ZM157.001 75.968H130.014V76.968H157.001V75.968ZM158.501 74.468C158.501 75.2965 157.829 75.968 157.001 75.968V76.968C158.381 76.968 159.501 75.8488 159.501 74.468H158.501ZM158.501 58.0114V74.468H159.501V58.0114H158.501ZM158.5 57.9746C158.501 57.9868 158.501 57.9991 158.501 58.0114H159.501C159.501 57.9911 159.5 57.9709 159.5 57.9508L158.5 57.9746ZM158.5 50.7061V57.9627H159.5V50.7061H158.5ZM157 49.2061C157.829 49.2061 158.5 49.8776 158.5 50.7061H159.5C159.5 49.3253 158.381 48.2061 157 48.2061V49.2061Z'
            fill='#23B3E5'
        />
        <path
            d='M134.971 66.2395C134.971 68.3516 133.122 70.1231 130.765 70.1231C128.408 70.1231 126.559 68.3516 126.559 66.2395C126.559 64.1275 128.408 62.356 130.765 62.356C133.122 62.356 134.971 64.1275 134.971 66.2395Z'
            stroke='#23B3E5'
        />
        <path
            d='M102 103.214H34V115H102V103.214Z'
            stroke='#23B3E5'
            strokeMiterlimit='10'
        />
        <path d='M87 103.214V115' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M95 103.214V115' stroke='#23B3E5' strokeLinejoin='round' />
        <path
            d='M108 92.4106H40V103.214H108V92.4106Z'
            stroke='#23B3E5'
            strokeMiterlimit='10'
        />
        <path d='M93 92.4106V102.232' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M101 92.4106V102.232' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M46 92.4106V102.232' stroke='#23B3E5' strokeLinejoin='round' />
        <path
            d='M104 81.6072H37V92.4107H104V81.6072Z'
            stroke='#23B3E5'
            strokeMiterlimit='10'
        />
        <path d='M90 81.6072V92.4107' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M98 81.6072V92.4107' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M43 81.6072V92.4107' stroke='#23B3E5' strokeLinejoin='round' />
        <path
            d='M113 70.8035H45V81.607H113V70.8035Z'
            stroke='#23B3E5'
            strokeMiterlimit='10'
        />
        <path d='M106 70.8035V80.6249' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M99 70.8035V80.6249' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M52 70.8035V80.6249' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M62 70.8035V80.6249' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M109 60H41V70.8036H109V60Z' stroke='#23B3E5' strokeMiterlimit='10' />
        <path d='M94 60V69.8214' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M102 60V69.8214' stroke='#23B3E5' strokeLinejoin='round' />
        <path d='M47 60V69.8214' stroke='#23B3E5' strokeLinejoin='round' />
    </svg>
);

export default ConnectWallet;
