import React from 'react';
import ConnectWalletModal from './ConnectWalletModal';
import ErrorModal from './status/ErrorModal';
import LoadingModal from './status/LoadingModal';
import SuccessModal from './status/SuccessModal';
import WaitingModal from './status/WaitingModal';
import WarningModal from './status/WarningModal';

const ModalsContainer: React.FC = () => (
    <>
        <ErrorModal />
        <WarningModal />
        <SuccessModal />
        <LoadingModal />
        <WaitingModal />
        <ConnectWalletModal />
    </>
);

export default ModalsContainer;
