const FooterArrow = () => {
    return (
        <svg
            width='17'
            height='9'
            viewBox='0 0 17 9'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <line x1='4.37114e-08' y1='4.5' x2='12' y2='4.5' stroke='#23B3E5' />
            <path
                d='M16.25 4.93301C16.5833 4.74056 16.5833 4.25944 16.25 4.06699L11 1.0359C10.6667 0.843449 10.25 1.08401 10.25 1.46891V7.53109C10.25 7.91599 10.6667 8.15655 11 7.9641L16.25 4.93301Z'
                fill='#23B3E5'
            />
        </svg>
    );
};

export default FooterArrow;
