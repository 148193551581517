const Success = () => (
    <svg
        width='117'
        height='117'
        viewBox='0 0 117 117'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <circle cx='58.5' cy='58.5' r='58.5' fill='#23D5B0' />
        <mask id='path-2-inside-1' fill='white'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M35.5355 52.4056C33.5829 54.3582 33.5829 57.5241 35.5355 59.4767L51.799 75.7402C53.7516 77.6928 56.9174 77.6928 58.8701 75.7402L85.7401 48.8701C87.6927 46.9175 87.6927 43.7516 85.7401 41.799C83.7875 39.8464 80.6217 39.8464 78.669 41.799L55.3345 65.1336L42.6066 52.4056C40.654 50.453 37.4882 50.453 35.5355 52.4056Z'
            />
        </mask>
        <path
            d='M55.3345 65.1336L54.6274 65.8407L55.3345 66.5478L56.0416 65.8407L55.3345 65.1336ZM36.2426 58.7696C34.6805 57.2075 34.6805 54.6748 36.2426 53.1127L34.8284 51.6985C32.4853 54.0417 32.4853 57.8407 34.8284 60.1838L36.2426 58.7696ZM52.5061 75.033L36.2426 58.7696L34.8284 60.1838L51.0919 76.4473L52.5061 75.033ZM58.163 75.033C56.6009 76.5951 54.0682 76.5951 52.5061 75.033L51.0919 76.4473C53.435 78.7904 57.234 78.7904 59.5772 76.4473L58.163 75.033ZM85.033 48.163L58.163 75.033L59.5772 76.4473L86.4472 49.5772L85.033 48.163ZM85.033 42.5061C86.5951 44.0682 86.5951 46.6009 85.033 48.163L86.4472 49.5772C88.7904 47.2341 88.7904 43.4351 86.4472 41.0919L85.033 42.5061ZM79.3762 42.5061C80.9382 40.944 83.4709 40.944 85.033 42.5061L86.4472 41.0919C84.1041 38.7488 80.3051 38.7488 77.9619 41.0919L79.3762 42.5061ZM56.0416 65.8407L79.3762 42.5061L77.9619 41.0919L54.6274 64.4264L56.0416 65.8407ZM41.8995 53.1127L54.6274 65.8407L56.0416 64.4264L43.3137 51.6985L41.8995 53.1127ZM36.2426 53.1127C37.8047 51.5506 40.3374 51.5506 41.8995 53.1127L43.3137 51.6985C40.9706 49.3554 37.1716 49.3554 34.8284 51.6985L36.2426 53.1127Z'
            fill='white'
            mask='url(#path-2-inside-1)'
        />
    </svg>
);

export default Success;
