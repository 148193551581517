const Trezor = () => (
    <svg width='26' height='38' xmlns='http://www.w3.org/2000/svg' id='trezor'>
        <g>
            <rect
                fill='none'
                id='canvas_background'
                height='40'
                width='28'
                y='-1'
                x='-1'
            />
        </g>
        <g>
            <path
                stroke='null'
                d='m12.995243,0.102334c-5.131274,0 -9.28393,4.152656 -9.28393,9.28393l0,3.479532c-1.8019,0.326206 -3.608979,0.761148 -3.608979,1.325536l0,18.158807s0,0.502254 0.564388,0.740436c2.045261,0.82846 10.091678,3.676291 11.94018,4.328703c0.238182,0.088024 0.305495,0.088024 0.367629,0.088024c0.088024,0 0.129447,0 0.367629,-0.088024c1.848501,-0.652412 9.915631,-3.500243 11.960891,-4.328703c0.522965,-0.217471 0.543677,-0.719725 0.543677,-0.719725l0,-18.179519c0,-0.564388 -1.781189,-1.020041 -3.588267,-1.325536l0,-3.479532c0.025889,-5.131274 -4.152656,-9.28393 -9.263218,-9.28393zm0,4.437439c3.023879,0 4.851669,1.82779 4.851669,4.851669l0,3.023879c-3.391508,-0.238182 -6.28594,-0.238182 -9.69816,0l0,-3.023879c0,-3.029057 1.82779,-4.851669 4.846491,-4.851669zm-0.020711,12.32852c4.219968,0 7.761635,0.326206 7.761635,0.911306l0,11.32919c0,0.176048 -0.020711,0.196759 -0.176048,0.258894c-0.150158,0.067312 -7.197246,2.609649 -7.197246,2.609649s-0.284783,0.088024 -0.367629,0.088024c-0.088024,0 -0.367629,-0.108735 -0.367629,-0.108735s-7.047088,-2.542337 -7.197246,-2.609649s-0.176048,-0.088024 -0.176048,-0.258894l0,-11.32919c-0.041423,-0.5851 3.500243,-0.890594 7.720212,-0.890594z'
                id='path7'
                fill='#fff'
            />
        </g>
    </svg>
);

export default Trezor;
