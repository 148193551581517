import { parseEther } from '@ethersproject/units';
import { BigNumber } from 'ethers';
import { useMemo } from 'react';
import { ErrorIcon } from '../../../../assets/icons';
import useIsAllowedToStakeForUser from '../../../../hooks/web3/vault/useIsAllowedToStakeForUser';
import useIsCanStakeFor from '../../../../hooks/web3/vault/useIsCanStakeFor';

type StakeOnBehalfWarningsProps = {
    stakeForAddress: string;
    amount: string;
    userBalance: BigNumber;
};

const StakeOnBehalfWarnings: React.FC<StakeOnBehalfWarningsProps> = ({
    stakeForAddress,
    amount,
    userBalance,
}) => {
    const { isCanStakeFor, isLoading: isCheckingCanStakeFor } = useIsCanStakeFor();

    const { isAllowedToStakeForUser, isLoading: isCheckingisAllowedToStakeFor } =
        useIsAllowedToStakeForUser(stakeForAddress);

    const isAllowedToStake = useMemo(
        () => isCanStakeFor && isAllowedToStakeForUser,
        [isCanStakeFor, isAllowedToStakeForUser]
    );

    const isAmountExceeded = useMemo(() => {
        if (amount !== '') {
            return parseEther(amount).gt(userBalance);
        }
        return false;
    }, [amount, userBalance]);

    return (
        <>
            <div className='warning'>
                {!isCanStakeFor && !isCheckingCanStakeFor && (
                    <>
                        <ErrorIcon />
                        <span>You are not allowed to stake for others</span>
                    </>
                )}
                {stakeForAddress && !isAllowedToStake && !isCheckingisAllowedToStakeFor && (
                    <>
                        <ErrorIcon />
                        <span>You're not allowed to stake for this address</span>
                    </>
                )}
                {isAmountExceeded && isAllowedToStake && (
                    <>
                        <ErrorIcon />
                        <span>Amount exceeds balance of token in wallet</span>
                    </>
                )}
                {isCheckingCanStakeFor && (
                    <>
                        <span>Checking address can stake for...</span>
                    </>
                )}
                {isCheckingisAllowedToStakeFor && (
                    <>
                        <span>Checking if you can stake for others...</span>
                    </>
                )}
            </div>
        </>
    );
};

export default StakeOnBehalfWarnings;
